.App {
  display: flex;
  flex-direction: column; /* Added */
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: none;
}

.neonTitle {
  font-size: 2em;
  color: orange;
  text-shadow:
    0 0 5px purple,
    0 0 10px purple,
    0 0 15px purple,
    0 0 20px purple,
    0 0 30px purple,
    0 0 40px purple;
}

.subTitle {
  font-size: 1em;
  color: orange;
  text-shadow:
    0 0 5px purple,
    0 0 10px purple,
    0 0 15px purple,
    0 0 20px purple,
    0 0 30px purple,
    0 0 40px purple;
}