.background-animation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    background-color: black;
}
.falling-circle {
    position: absolute;
    background-color: #ffffff; /* white stars */
    border-radius: 50%;
    animation: fall 5s linear infinite; /* slower falling speed */
    top: -25px;
}
@keyframes fall {
    0% {
        transform: translateY(-100%);
        opacity: 1;
    }
    100% {
        transform: translateY(100vh);
        opacity: 0;
    }
}